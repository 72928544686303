import React from 'react';
import PropTypes from 'prop-types';

let measure = 'rem';
if (typeof(window) !== "undefined") {
  if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ){
      measure = 'em';
  }
}

const icons = {
  accessibility: {
    paths: [
      {
        d: 'M43.76,23.79C43.3,15.35,39.58,6.46,32.06,3.06c-.92-1.05-4-1.4-5.36-1.92C13.41-3.75,1.49,7.85.17,20.48c-1.36,13,5.47,24.62,19.52,24.7C32.25,45.25,44.53,37.61,43.76,23.79Z',
        className: "accessibility-color--dark"
      },
      {
        d: 'M22,23.07V18l-.08-.06c-.4.42-.82.84-1.21,1.27a1.21,1.21,0,0,1-1,.4c-1.1,0-2.21,0-3.31,0a.94.94,0,1,1,0-1.86c.94,0,1.89,0,2.83,0a.76.76,0,0,0,.47-.19c.82-.8,1.63-1.62,2.44-2.43a7.35,7.35,0,0,1,.73-.76,2,2,0,0,1,3.22,1.42,4.1,4.1,0,0,1,0,.44q0,3.44,0,6.89a2,2,0,0,1-1.15,2,3,3,0,0,1-1.15.21c-.75,0-1.5,0-2.25,0s-1.41,0-2.11,0a.67.67,0,0,0-.68.36c-1,1.64-2.1,3.27-3.15,4.91a1.18,1.18,0,0,1-1.43.62,1.12,1.12,0,0,1-.56-1.71c.46-.76,1-1.5,1.43-2.24.74-1.16,1.5-2.3,2.23-3.47a1.44,1.44,0,0,1,1.35-.74c1.1,0,2.2,0,3.33,0',
        className: "accessibility-color--light"
      },
      {
        d: 'M29.73,26.59a5.64,5.64,0,0,0-2.09-4.37L27.36,22a.89.89,0,0,1,1.13-1.37,7.07,7.07,0,0,1,2.21,2.6,7.3,7.3,0,0,1-.34,7.38A7.43,7.43,0,0,1,19.1,32.14a7.7,7.7,0,0,1-1.54-1.9.91.91,0,0,1,.26-1.31.88.88,0,0,1,1.27.38,5.67,5.67,0,0,0,10.64-2.73',
        className: "accessibility-color--light"
      },
      {
        d: 'M25.37,10.81a2.47,2.47,0,1,1-2.45-2.47,2.49,2.49,0,0,1,2.45,2.47',
        className: "accessibility-color--light"
      },
      {
        d: 'M17.27,21.85c-.89,0-1.77,0-2.66,0a.57.57,0,0,1-.58-.37l-1.86-4c-.17-.36-.11-.6.16-.73s.48,0,.66.36c.56,1.18,1.11,2.36,1.66,3.55a.4.4,0,0,0,.43.28h5c.27,0,.46.18.45.46a.42.42,0,0,1-.47.43h-2.8Z',
        className: "accessibility-color--light"
      }
      
    ],
    width: '3' + measure,
    height: '3' + measure,
    vbwidth: 43.8,
    vbheight: 45.18
  },
  accessibilityHover: {
    paths: [
      {
        d: 'M20.86,47.17h-.18c-6.18,0-11.4-2.24-15.1-6.37C1.35,36.07-.62,29,.17,21.37A25.12,25.12,0,0,1,11.47,2.93,18.6,18.6,0,0,1,28,1.2c.39.14,1,.28,1.59.43,1.54.37,3.13.76,4,1.59,8.27,3.85,11.68,13.61,12.12,21.5h0a19.35,19.35,0,0,1-5.28,14.79C35.91,44.31,28.58,47.17,20.86,47.17ZM21.43,2a16.86,16.86,0,0,0-8.91,2.64,23.09,23.09,0,0,0-10.36,17c-.74,7.06,1.05,13.58,4.91,17.89,3.31,3.7,8,5.67,13.62,5.7,7.23.06,14.11-2.59,18.34-7a17.4,17.4,0,0,0,4.73-13.3h0C43.35,17.47,40.22,8.39,32.64,5l-.2-.09-.14-.17c-.42-.48-2.05-.87-3.14-1.14a17.68,17.68,0,0,1-1.81-.5A17,17,0,0,0,21.43,2Z',
        className: "accessibility-color--dark"
      },
      {
        d: 'M22.58,24.06V19l-.08-.06c-.4.42-.82.84-1.21,1.27a1.21,1.21,0,0,1-1,.4c-1.1,0-2.21,0-3.31,0a.94.94,0,1,1,0-1.86c.94,0,1.89,0,2.83,0a.76.76,0,0,0,.47-.19c.82-.8,1.63-1.62,2.44-2.43a7.35,7.35,0,0,1,.73-.76,2,2,0,0,1,3.22,1.42,4.1,4.1,0,0,1,0,.44q0,3.44,0,6.89a2,2,0,0,1-1.15,2,3,3,0,0,1-1.15.21c-.75,0-1.5,0-2.25,0s-1.41,0-2.11,0a.67.67,0,0,0-.68.36c-1,1.64-2.1,3.27-3.15,4.91a1.18,1.18,0,0,1-1.43.62,1.12,1.12,0,0,1-.56-1.71c.46-.76,1-1.5,1.43-2.24.74-1.16,1.5-2.3,2.23-3.47a1.44,1.44,0,0,1,1.35-.74c1.1,0,2.2,0,3.33,0',
        className: "accessibility-color--dark"
      },
      {
        d: 'M30.34,27.58a5.64,5.64,0,0,0-2.09-4.37L28,23a.89.89,0,0,1,1.13-1.37,7.07,7.07,0,0,1,2.21,2.6A7.3,7.3,0,0,1,31,31.57a7.43,7.43,0,0,1-11.26,1.56,7.7,7.7,0,0,1-1.54-1.9.91.91,0,0,1,.26-1.31.88.88,0,0,1,1.27.38,5.67,5.67,0,0,0,10.64-2.73',
        className: "accessibility-color--dark"
      },
      {
        d: 'M26,11.8a2.47,2.47,0,1,1-2.45-2.47A2.49,2.49,0,0,1,26,11.8',
        className: "accessibility-color--dark"
      },
      {
        d: 'M17.88,22.84c-.89,0-1.77,0-2.66,0a.57.57,0,0,1-.58-.37l-1.86-4c-.17-.36-.11-.6.16-.73s.48,0,.66.36c.56,1.18,1.11,2.36,1.66,3.55a.4.4,0,0,0,.43.28h5c.27,0,.46.18.45.46a.42.42,0,0,1-.47.43h-2.8Z',
        className: "accessibility-color--dark"
      }
    ],
    width: '3' + measure,
    height: '3' + measure,
    vbwidth: 45.79,
    vbheight: 47.17
  }
};

class IconHoverChange extends React.Component {
  
  render() {
    const width = icons[this.props.icon].width;
    const height = icons[this.props.icon].height;
    const vbwidth = icons[this.props.icon].vbwidth;
    const vbheight = icons[this.props.icon].vbheight;
    return(
      <svg
        className="svg-icon"
        width={width}
        height={height}
        viewBox={`0 0 ${vbwidth} ${vbheight}`}
      >
      {
        icons[this.props.icon].paths.map((spath, i) => <path className={spath.className} d={spath.d} key={i}></path>)
      }
      </svg>
    )
  };
};

IconHoverChange.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default IconHoverChange;