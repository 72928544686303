export const translations = {
  "he": {
    "homepageEventsTitle": "זיוה קיינר - אמנית",
    "readMore": "<< קראו עוד",
    "discoverMore": "<< גלו עוד",
    "update": "עדכון",
    "nextPainting": "הבא >",
    "prevPainting": "< הקודם",
    "shareWithFriends": "שתפו עם חברים!",
    "headerPlaceholder": "כותרת",
    "textPlaceholder": "טקסט",
    "linkToPage": "קישור לדף",
    "namePlaceholder": "*שם:",
    "phonePlaceholder": "*טלפון:",
    "emailPlaceholder": "*דוא״ל:",
    "messagePlaceholder": "הודעה:",
    "sendMessage": "שלחו הודעה",
    "send": "שלח",
    "allRightsReserved": "© כל הזכויות שמורות לזיוה קיינר",
    "programmingBy": "אתר של frixell",
    "joinNewsletter": "הצטרפו לרשימת התפוצה של זיוה קיינר"
  },
  "en": {
    "homepageEventsTitle": "Ziva Kainer - Artist",
    "readMore": "Read More >>",
    "discoverMore": "Discover More >>",
    "update": "update",
    "nextPainting": "Next >",
    "prevPainting": "< Previous",
    "shareWithFriends": "Share this!",
    "headerPlaceholder": "Header",
    "textPlaceholder": "Text",
    "linkToPage": "Link to page",
    "namePlaceholder": "*Name:",
    "phonePlaceholder": "*Phone:",
    "emailPlaceholder": "*Email:",
    "messagePlaceholder": "Message:",
    "sendMessage": "Send message",
    "send": "Send",
    "allRightsReserved": "© All rights reserved to Ziva Kainer",
    "programmingBy": "Website by frixell",
    "joinNewsletter": "Join Ziva Kainer's mailinglist"
  }
}