import React from 'react';

let measure = 'vw';
if (typeof(window) !== "undefined") {
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ){
        measure = 'em';
    }
}

const HomepageSocialmediaRightBorder = () => {
    return(
        <div className="homepage__socialmedia__right-border">
            <svg width={'0.2'+measure} height={'105%'} viewBox="0 40 4 90">
                <path d="M1.9,13.08c-.07.18-.23.28-.25.5a1.47,1.47,0,0,0,.75.36c.13-.17.31-.31.25-.65-.45.1-.51-.14-.75-.22m1.25-.5c.75,1.15-.48,2.52-1.41,2.16a2,2,0,0,1-.83-1.95c.21-.57,1.64-.71,2.24-.21"/>
                <path d="M1.33,7.16c.34-.56-.32-.52,0,0m.91,0a.31.31,0,1,0,0,.58c.05-.16.16-.44,0-.58m1-.14A1.24,1.24,0,0,1,2.16,8.53c-.94.06-2.27-1.9-1-2.45A1.71,1.71,0,0,1,3.24,7"/>
                <path d="M2,1c-.59,0-.69,1,0,.94.46-.19.11-.64,0-.94M1.1.17c2.35-1,2.39,2.84.41,2.38C.8,2.38.63.81,1.1.17"/>
                <path d="M1.69,40.09c.18-.13.39-.24.33-.58-.47-.14-.67.46-.33.58m1.08-1.44c.59,1,.83,2.39-.67,2.38-.91,0-1.37-1-1.24-1.88.15-.55,1.19-.68,1.91-.5"/>
                <path d="M1.8,71.74c-.07.18-.23.28-.25.5a1.47,1.47,0,0,0,.75.36c.13-.17.31-.31.25-.65-.45.1-.51-.14-.75-.22M3,71.24c.75,1.15-.48,2.52-1.41,2.16a2,2,0,0,1-.83-1.95c.21-.57,1.64-.71,2.24-.21"/>
                <path d="M2.2,33.17c0-.1,0-.19,0-.29-.5-.1-.36.55,0,.29m-.41-.72a.61.61,0,0,0,.41-.07c0-.07,0-.14,0-.22-.27,0-.4.09-.42.29m-.41-1.08a1.78,1.78,0,0,1,1.24.07c.92.43.67,1.67.08,2.24-1.83.85-2.3-1.51-1.32-2.31"/>
                <path d="M1.23,65.83c.34-.56-.32-.52,0,0m.91,0a.31.31,0,1,0,0,.58c.05-.16.16-.44,0-.58m1-.14A1.24,1.24,0,0,1,2.06,67.2c-.94.06-2.27-1.9-1-2.45a1.71,1.71,0,0,1,2.07.94"/>
                <path d="M1.35,45.64c0,.17-.14.21-.08.43.34.17.9,0,.83-.43-.17-.14-.55-.06-.75,0m.33,1.15a.69.69,0,0,0,1-.58,1.27,1.27,0,0,0-1,.58M.52,45.28c1.61-1.78,3.84,1.58,1.82,2-1.18.22-1.8-.95-1.82-2"/>
                <path d="M1.91,59.63c-.59,0-.69,1,0,.94.46-.19.11-.64,0-.94M1,58.84c2.35-1,2.39,2.84.41,2.38C.7,61.05.53,59.48,1,58.84"/>
                <path d="M1.55,26.53c.56,0,.86-.17,1-.5-.24.25-1,.06-1,.5m-.33.65c-.89-.49-.87-2.13.17-2.31,2.25-.38,2.73,2.5-.17,2.31"/>
                <path d="M2.81,18.53c.31,1.63-2.39,1.59-2.32.14a1.18,1.18,0,0,1,2.32-.14"/>
                <path d="M2.58,52.78a1.42,1.42,0,0,1-1.41,1.58C.45,54.31-.42,52.5.51,51.91a1.53,1.53,0,0,1,2.07.87"/>
                <path d="M1.59,99.78c.18-.13.39-.24.33-.58-.47-.14-.67.46-.33.58m1.08-1.44c.59,1,.83,2.39-.67,2.38-.91,0-1.37-1-1.24-1.88.15-.55,1.19-.68,1.91-.5"/>
                <path d="M2.1,92.85c0-.1,0-.19,0-.29-.5-.1-.36.55,0,.29m-.41-.72a.61.61,0,0,0,.41-.07c0-.07,0-.14,0-.22-.27,0-.4.09-.42.29M1.27,91a1.78,1.78,0,0,1,1.24.07c.92.43.67,1.67.08,2.24C.77,94.21.3,91.85,1.27,91"/>
                <path d="M1.25,105.33c0,.17-.14.21-.08.43.34.17.9,0,.83-.43-.17-.14-.55-.06-.75,0m.33,1.15a.69.69,0,0,0,1-.58,1.27,1.27,0,0,0-1,.58M.42,105c1.61-1.78,3.84,1.58,1.82,2-1.18.22-1.8-.95-1.82-2"/>
                <path d="M1.8,119.32c-.59,0-.69,1,0,.94.46-.19.11-.64,0-.94m-.91-.79c2.35-1,2.39,2.84.41,2.38-.71-.16-.88-1.73-.41-2.38"/>
                <path d="M1.44,86.22c.56,0,.86-.17,1-.5-.24.25-1,.06-1,.5m-.33.65c-.89-.49-.87-2.13.17-2.31,2.25-.38,2.73,2.5-.17,2.31"/>
                <path d="M2.7,78.22C3,79.84.31,79.81.38,78.36a1.18,1.18,0,0,1,2.32-.14"/>
                <path d="M2.48,112.47a1.42,1.42,0,0,1-1.41,1.58C.35,114-.53,112.19.41,111.6a1.53,1.53,0,0,1,2.07.87"/>
                <path d="M1.59,133.68c.18-.13.39-.24.33-.58-.47-.14-.67.46-.33.58m1.08-1.44c.59,1,.83,2.39-.67,2.38-.91,0-1.37-1-1.24-1.88.15-.55,1.19-.68,1.91-.5"/>
                <path d="M2.1,126.76c0-.1,0-.19,0-.29-.5-.1-.36.55,0,.29M1.68,126A.61.61,0,0,0,2.1,126c0-.07,0-.14,0-.22-.27,0-.4.09-.42.29M1.27,125a1.78,1.78,0,0,1,1.24.07c.92.43.67,1.67.08,2.24-1.83.85-2.3-1.51-1.32-2.31"/>
                <path d="M1.25,139.24c0,.17-.14.21-.08.43.34.17.9,0,.83-.43-.17-.14-.55-.06-.75,0m.33,1.15a.69.69,0,0,0,1-.58,1.27,1.27,0,0,0-1,.58M.42,138.87c1.61-1.78,3.84,1.58,1.82,2-1.18.22-1.8-.95-1.82-2"/>
                <path d="M2.48,146.38A1.42,1.42,0,0,1,1.06,148c-.72-.05-1.59-1.86-.66-2.45a1.53,1.53,0,0,1,2.07.87"/>
            </svg>
        </div>
    )
};

export default HomepageSocialmediaRightBorder;